import { useCallback } from 'react';
import { removeLocalStorageItem } from '@/ext/lib/storage';

import { UserAPI } from '../../../rpc/users';

const api = new UserAPI();

export const useLogout = (): (() => Promise<void>) =>
  useCallback(async () => {
    removeLocalStorageItem('sync-user');
    await api.logout();
    window.location.href = '/';
  }, []);
