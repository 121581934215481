import {
  atom,
  SetterOrUpdater,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

/**
 * Menu state
 */
export const menuMobileOpenState = atom<boolean>({
  key: 'menuMobileOpenState',
  default: false,
});

export const useIsMenuMobileOpen = (): boolean =>
  useRecoilValue(menuMobileOpenState);

export const useSetIsMenuMobileOpen = (): SetterOrUpdater<boolean> =>
  useSetRecoilState(menuMobileOpenState);

/**
 * Settings state
 */
export const settingsMobileOpenState = atom<boolean>({
  key: 'settingsMobileState',
  default: false,
});

export const useIsSettingsMobileOpen = (): boolean =>
  useRecoilValue(settingsMobileOpenState);

export const useSetIsSettingsMobileOpen = (): SetterOrUpdater<boolean> =>
  useSetRecoilState(settingsMobileOpenState);
