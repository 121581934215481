import { FunctionComponent } from 'react';
import {
  Tooltip as MuiTooltip,
  TooltipProps as MUITooltipProps,
} from '@mui/material';

import { Defer } from '@/components/Defer';

export interface TooltipProps extends MUITooltipProps {}

const Tooltip: FunctionComponent<TooltipProps> = ({ children, ...props }) => (
  <Defer fallback={children} duration={1000}>
    <MuiTooltip {...props}>{children}</MuiTooltip>
  </Defer>
);

export default Tooltip;
