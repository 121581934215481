import { FunctionComponent } from 'react';

import {
  Box,
  Button,
  styled,
  useTheme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { Gear } from '../icons/Gear';
import { useCloseDialog, useSetDialog } from '../state/dialog';
import { useSetIsSettingsMobileOpen } from '../state/mobile';
import { useUser } from '../state/user';
import Avatar from '../ui/Avatar';
import UpgradeButton from '@/components/molecules/UpgradeButton';

export interface UserSettingsProps {
  handleOpenSettingsMenu?: (value: boolean) => void;
  useStandaloneDialogs?: boolean;
}

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 1.5, 2),
  alignItems: 'flex-end',
  borderBottom: `1.5px solid ${theme.palette.line?.disabled}`,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flexWrap: 'wrap',

  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
  },
}));

const PrimaryContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  paddingBottom: theme.spacing(1.5),

  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
  },
}));

const EditButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.disabled,
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(1.5),
}));

const Email = styled(Typography)(({ theme }) => ({
  color: theme.palette.helper,
}));

const Info = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  marginLeft: theme.spacing(1),
}));

const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const SettingsButton = styled(Gear)(({ theme }) => ({
  color: theme.palette.text.disabled,
  cursor: 'pointer',
  height: theme.spacing(4.5),
  padding: theme.spacing(1.5),
  width: theme.spacing(4.5),
}));

const UserSettings: FunctionComponent<UserSettingsProps> = ({
  handleOpenSettingsMenu,
  useStandaloneDialogs = false,
}) => {
  const setDialog = useSetDialog();
  const closeDialog = useCloseDialog();
  const user = useUser();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const setSettingsMobileOpen = useSetIsSettingsMobileOpen();

  const minimizeText = (maxChar: number, text: string): string => {
    if (!text) {
      return '';
    }

    return text.length > maxChar ? `${text.substring(0, maxChar)}...` : text;
  };

  const handleEditButton = () => {
    if (handleOpenSettingsMenu) {
      handleOpenSettingsMenu(false);
      closeDialog();
      setDialog({ dialog: 'userProfile' });
    }
  };

  const handleSettingsButton = () => {
    if (small) {
      setSettingsMobileOpen(true);
    }
  };

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" width="100%">
        <PrimaryContainer>
          <Avatar user={user} />
          <Info>
            <Name variant="subtitle1">
              {minimizeText(25, user?.name || '')}
            </Name>
            <Email variant="body2">{minimizeText(30, user?.email || '')}</Email>
          </Info>
        </PrimaryContainer>
        {small ? (
          <SettingsButton onClick={handleSettingsButton} />
        ) : (
          <EditButton
            onClick={!useStandaloneDialogs ? handleEditButton : undefined}
            variant="text"
            href={useStandaloneDialogs ? '/d/userProfile' : undefined}
          >
            Edit
          </EditButton>
        )}
      </Box>
      {user && (
        <UpgradeButton
          variant="primary"
          subscription={user?.subscription}
          onClick={() => handleOpenSettingsMenu?.(false)}
        />
      )}
    </Container>
  );
};

export default UserSettings;
