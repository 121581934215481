import { FunctionComponent, useState, useCallback, useMemo } from 'react';

import Route from 'next/link';
import { Menu } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { truncate } from 'lodash/fp';

import { useRecoilValue } from 'recoil';
import { TrackableEvent, TrackableTarget } from '../../lib/trackable';
import { Close } from '../icons/Close';
import { useTracking } from '../state/tracking';
import { useUserLoadable } from '../state/user';
import UserSettings from '../user/UserSettings';
import { useLogout } from '../utils/useLogout';
import WaldoMenuSettingsItems from './WaldoMenuSettingsItems';
import { COMMIT_SHA } from '../utils/version';
import { EXTENSION_ID } from '../utils/sendBackgroundMessage';
import SourceEngineSwitch from './SourceEngineSwitch';
import { searchQueryState } from '../state/waldoQuery';
import { currentGoogleUrlState } from '../state/google';
import { useDeveloperToolVisibility } from '@/lib/utils/useDeveloperToolVisibility';
import withSuspense from '../utils/withSuspense';

export interface SettingsMenuProps extends BoxProps {
  useStandaloneDialogs?: boolean;
}

const MenuButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,

  '&:hover': {
    backgroundColor: theme.palette.fill?.disabled,
  },
}));

const MenuDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,

  '& .MuiBackdrop-root': {
    background: 'transparent',
  },
}));

const MenuItem = styled(Button)(() => ({
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const RightSideSwitch = styled(SourceEngineSwitch)(() => ({
  marginLeft: 'auto',
  marginRight: 0,
}));

const SignInButton = styled(Button)(({ theme }) => ({
  height: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}));

const SettingsMenu: FunctionComponent<SettingsMenuProps> = ({
  useStandaloneDialogs = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const trackEvent = useTracking();
  const loadable = useUserLoadable();
  const user = loadable.valueMaybe();
  const logout = useLogout();
  const query = useRecoilValue(searchQueryState);
  const googleUrl = useRecoilValue(currentGoogleUrlState);
  const showBuildInfo = useDeveloperToolVisibility();

  const handleToggle = () => {
    setOpen(!open);
    trackEvent(TrackableEvent.ACTION, {
      target: TrackableTarget.TOGGLE_SETTINGS,
    });
  };

  const handleSignout = () => logout();

  const handleClickExtensionId = useCallback(() => {
    // use prompt for this developer only util
    // eslint-disable-next-line no-alert
    const eid = prompt('Enter extension ID (cancel to clear)', EXTENSION_ID);

    if (eid) {
      localStorage.setItem('OVERRIDE_EXTENSION_ID', eid);
    } else {
      localStorage.removeItem('OVERRIDE_EXTENSION_ID');
    }

    window.location.reload();
  }, []);

  const signInLink = useMemo(() => {
    try {
      const url = new URL('/sign-in', window.location.origin);
      url.searchParams.set(
        'redirect',
        `${window.location.pathname}${window.location.search}`,
      );
      return url.toString();
    } catch (e) {
      return '/sign-in';
    }
  }, []);

  return (
    <Box {...props}>
      {!user ? (
        <SignInButton color="primary" variant="outlined" href={signInLink}>
          <Typography
            component="span"
            marginLeft={0.25}
            variant="subtitle2"
            color="primary"
          >
            Sign In
          </Typography>
        </SignInButton>
      ) : (
        <>
          <MenuButton
            data-component="SettingsMenuButton"
            onClick={handleToggle}
            size="small"
          >
            <Menu />
          </MenuButton>
          <MenuDrawer
            anchor="right"
            disableScrollLock
            open={open}
            onClose={handleToggle}
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
            >
              <Stack spacing={1.5} minWidth={400} width="20%">
                <Box display="flex" justifyContent="flex-end" padding={1}>
                  <IconButton onClick={handleToggle} size="small">
                    <Close fontSize="inherit" sx={{ color: 'icon.high' }} />
                  </IconButton>
                </Box>
                {user?.email && (
                  <UserSettings
                    handleOpenSettingsMenu={setOpen}
                    useStandaloneDialogs={useStandaloneDialogs}
                  />
                )}
                <Box paddingX={1}>
                  <List disablePadding>
                    <ListItem>
                      <Typography color="helper" variant="body2">
                        Waldo Settings
                      </Typography>
                    </ListItem>
                    <WaldoMenuSettingsItems
                      setOpen={setOpen}
                      useStandaloneDialogs={useStandaloneDialogs}
                    />
                    {user?.email.endsWith('@waldo.fyi') && (
                      <ListItem key="bing">
                        <Typography variant="button">Use Bing</Typography>
                        <RightSideSwitch />
                      </ListItem>
                    )}
                  </List>
                </Box>
              </Stack>
              {showBuildInfo && (
                <Box maxWidth={400} p={2}>
                  <Typography color="helper" variant="caption">
                    <strong>Build info</strong>
                    <br />
                    API: {process.env.NEXT_PUBLIC_API_BASE}
                    <br />
                    NLP: {process.env.NEXT_PUBLIC_NLP_BASE}
                    <br />
                    EID:{' '}
                    <Button
                      variant="text"
                      onClick={handleClickExtensionId}
                      title="Click to change extension ID"
                    >
                      <Typography color="helper" variant="caption">
                        {EXTENSION_ID}
                      </Typography>
                    </Button>
                    <br />
                    GIT: {truncate({ length: 20 }, COMMIT_SHA || 'dev')}
                    <br />
                    URL: {googleUrl}
                    <br />
                    Q:
                    <pre style={{ whiteSpace: 'pre-wrap' }}>
                      {JSON.stringify(query, null, 2)}
                    </pre>
                    <br />
                  </Typography>
                </Box>
              )}
              <Box
                alignItems="center"
                display="flex"
                justifyContent={user ? 'space-between' : 'flex-end'}
                p={2}
              >
                {user && (
                  <MenuItem onClick={handleSignout} variant="text">
                    Sign out
                  </MenuItem>
                )}
              </Box>
            </Box>
          </MenuDrawer>
        </>
      )}
    </Box>
  );
};

export default withSuspense(SettingsMenu, { fallback: null });
